<template>
  <v-btn 
    @click="$emit('click')" 
    :class="{ 'mr-n7' : overflow }"
    :absolute="absolute || overflow"
    :right="right || overflow"
    :top="top || overflow"
    color="error"
    x-small
    fab
    
  >
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    absolute: Boolean,
    overflow: Boolean,
    right: Boolean,
    top: Boolean,
  }
}
</script>